import React, {useEffect, useState} from "react";
import {PCDLoader, PLYLoader, GLTFLoader} from "three-stdlib";

function PcdToView({geometry: {attributes: {position, color}}}) {
  return {views: [{points: position, colors: color}]}
}

export function PcdLoader({url, component: Comp, toProps, ...rest}) {
  // const src = "https://escher.ge.ngrok.io/files/fast_nerf/fast_nerf/vqn-dash/demo_data/files/view_1.json"
  const [{loading, error = null, ...props}, setData] = useState({loading: true})

  useEffect(() => {
    const pcdLoader = new PCDLoader();
    const plyLoader = new PLYLoader();
    const glbLoader = new GLTFLoader();
    setData(({loading: false, error: null}))
    if (url.endsWith(".pcd")) {
      pcdLoader.load(url, (pcd) => {
        const _props = toProps ? toProps(pcd) : {pcd}
        setData({loading: false, error: false, ..._props})
      }, ({loaded, total}) => {
      }, (err) => {
        setData(({loading, error, ...rest}) => ({loading: false, error: err, ...rest}))
      })
    }
    if (url.endsWith(".ply")) {
      plyLoader.load(url, (ply) => {
        const _props = toProps ? toProps(ply) : {ply}
        setData({loading: false, error: false, ..._props})
      }, ({loaded, total}) => {
      }, (err) => {
        setData(({loading, error, ...rest}) => ({loading: false, error: err, ...rest}))
      })
    }
    if (url.endsWith(".glb")) {
      glbLoader.load(url, (glb) => {
        const _props = toProps ? toProps(glb) : {glb}
        setData({loading: false, error: false, ..._props})
      }, ({loaded, total}) => {
      }, (err) => {
        setData(({loading, error, ...rest}) => ({loading: false, error: err, ...rest}))
      })
    }
  }, [url])

  return <Comp loading={loading} error={error} {...props} {...rest}/>
}

export function BatchPcdLoader({urls = [], component: Comp, toProps, ...rest}) {
  // const src = "https://escher.ge.ngrok.io/files/fast_nerf/fast_nerf/vqn-dash/demo_data/files/view_1.json"
  const [
    {
      loading, error = null,
      pcd_list = [],
      ply_list = [],
      glb_list = []
    },
    setData
  ] = useState({loading: true})

  useEffect(() => {
    const pcdLoader = new PCDLoader();
    const plyLoader = new PLYLoader();
    const glbLoader = new GLTFLoader()
    setData({
      loading: true,
      error: null,
      pcd_list: [],
      ply_list: [],
      glb_list: []
    })
    for (let url of urls) {
      if (url.endsWith(".pcd")) {
        pcdLoader.load(url, (pcd) => {
          setData(({pcd_list: prev_pcd_list = []}) => {
                return {
                  loading: false,
                  pcd_list: [...prev_pcd_list, pcd]
                }
              }
          )
        })
      }
      if (url.endsWith(".ply")) {
        plyLoader.load(url, (ply) => {
          setData(({ply_list: prev_ply_list = []}) => {
                return {
                  loading: false,
                  ply_list: [...prev_ply_list, ply]
                }
              }
          )
        })
      }
      if (url.endsWith(".glb")) {
        glbLoader.load(url, (glb) => {
          setData(({glb_list: prev_glb_list = []}) => {
                return {
                  loading: false,
                  glb_list: [...prev_glb_list, glb]
                }
              }
          )
        })
      }
    }
  }, [urls])

  return toProps
      // todo: not implemented
      ? <Comp loading={loading} error={error} {...toProps(pcd_list)} {...rest}/>
      : <Comp loading={loading} error={error} pcds={pcd_list} plys={ply_list} glbs={glb_list} {...rest}/>

}

