import React, {useState} from 'react'
import PointCloudView from "./components/_point-cloud";
import Editor from "@monaco-editor/react";
import {BatchPcdLoader} from "../../loaders/_pcd-loader";
import {parse} from "query-string";
import {document} from 'browser-monads';

function text2urls(text) {
    return text.split('\n').map(line => line.trim()).filter(l => (!!l))
}

const defaultText = `
https://escher.ge.ngrok.io/files/fast_nerf/fast_nerf/vqn-dash/demo_data/files/y-up/d415.pcd
https://escher.ge.ngrok.io/files/fast_nerf/fast_nerf/vqn-dash/demo_data/files/y-up/d435.pcd
`

export default function PointCloudDemo({}) {
    const {pcdPath = ""} = parse(document.location.search)

    const [{text, urls}, setState] = useState(
        {
            text: ("\n" + pcdPath) || defaultText,
            urls: text2urls(pcdPath || defaultText)
        });

    console.log(text, urls);

    function onChange(newText, e) {
        setState({
            text: newText,
            urls: text2urls(newText)
        });
    }

    if (typeof window == "undefined") return <div>loading</div>;
    return <div style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        height: "100%",
        width: "100%"
    }}>
        <Editor height="10vh" defaultLanguage="text" defaultValue={text} onChange={onChange}/>
        <BatchPcdLoader urls={urls} component={PointCloudView} width={640} height={480}/>
    </div>
}